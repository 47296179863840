import React from 'react'
import './Main.css'
import Navbar from './Navbar'
import yourImage from '../images/image_v1_1727256832_1753.jpeg.png';
import AI_img from '../images/image_v1_1727331154_5751.jpeg.png';
import crm_img from '../images/image_v1_1727327805_5013.jpeg.png'
import support_img from '../images/image_v1_1727332914_1547.jpeg.png';
import acc_img from '../images/image_v1_1727333931_4452.jpeg.png';
import { MdDoubleArrow } from "react-icons/md";
import { Link } from 'react-router-dom';

const Enterprise_AI_Platform = () => {
    return (
        <div className='enterprise_plathform'>
            <div className="row ms-xl-5 me-xl-5">
                <Navbar menu={'Products'} />
            </div>

            <div className='container-md ps-5 pe-5'>
                <div className='main-enter-plathform mt-5'>
                    <h4 style={{fontSize:'2rem' }}>Introducing Enterprise AI Platform™
                    </h4>
                </div>
                <div className='enter-plathform-text mt-4'>
                    <h6 class='main-pages-products-sub-text text-size'>
                        An integrated AI platform that helps to Digitialize and AI-fy Enterprise workflows such as Enterprise Resource Planning (ERP), Customer Relationship Management (CRM), Support, Accounting, etc to launch the business into the Era of Enterprise Intelligence.


                    </h6>
                </div>
                <div className='button_div_AI mb-2 mt-4'>
                    <Link to='/contact-sales' > <button className='btn contact_btn_EI'> Contact Sales </button></Link>
                    <Link to='/contact-marketing' > <button className='btn'>  Learn More <MdDoubleArrow />  </button></Link>
                </div>
                <div className='img-Tab p-2 mt-5'>
                    <img className='img_EI_APP' src={yourImage} alt='Enterprise AI Apps' />
                </div>

                <div className='main-enter-plathform ' style={{marginTop:'5rem'}}>
                    <h4 style={{fontSize:'1.5rem',fontWeight:'bold'}}>AI Enterprise Resource Planning (ERP) for Enterprise
                    </h4>
                </div>
                <div className='enter-plathform-text mt-4'>
                    <h6 class='main-pages-products-sub-text  text-size'>
                        We are revolutionizing ERP with our Enterprise AI Platform that is built with AI first principles. We support features that are needed for business to simplify operations and increase efficiency across the enterprise.
                    </h6>
                </div>

                <div className='img-Tab p-2 mt-1'>
                    <img className='img_EI_APP' src={AI_img} alt='Enterprise AI Apps' />
                </div>

                <div className='main-enter-plathform mt-3'>
                    <h4>Digitialize Business
                    </h4>
                </div>
                <div className='enter-plathform-text mt-2'>
                    <h6 class='main-pages-products-sub-text  text-size'>
                    Digitize your operations with AI directed workflows in a couple of clicks with minimal to no training.

                    </h6>
                </div>

                <div className='main-enter-plathform mt-3'>
                    <h4>AI-fy Operations

                    </h4>
                </div>
                <div className='enter-plathform-text mt-2'>
                    <h6 class='main-pages-products-sub-text  text-size'>
                    Integrate AI into operations to increase efficiency and accuracy across all the departments such as Human Resources, Finance, Supply Chain, Manufacturing, etc. 
                    </h6>
                </div>

                <div className='main-enter-plathform mt-3'>
                    <h4>AI-power Employees    </h4>
                </div>
                <div className='enter-plathform-text mt-2'>
                    <h6 class='main-pages-products-sub-text  text-size'>
                    Provide employees an AI-assistant that helps them with ‘getting things done’ with ease and on time.
                    </h6>
                </div>

                <div className='main-enter-plathform mt-5'>
                    <h4 style={{fontSize:'1.5rem',fontWeight:'bold'}}>AI Customer Relationship Management (CRM) for Enterprise

                    </h4>
                </div>
                <div className='enter-plathform-text mt-4'>
                    <h6 class='main-pages-products-sub-text  text-size'>
                    We are revolutionizing CRM for the better. With an integrated platform and the power of AI, We can make the customer journey magical throughout the marketing funnel from start to finish.
                    </h6>
                </div>

                <div className='img-Tab p-2 mt-4'>
                    <img className='img_EI_APP' src={crm_img} alt='Enterprise AI Apps' />
                </div>

                <div className='main-enter-plathform mt-5'>
                    <h4 style={{fontSize:'1.5rem',fontWeight:'bold'}}>AI Support for Enterprise
                    </h4>
                </div>
                <div className='enter-plathform-text mt-2'>
                    <h6 class='main-pages-products-sub-text  text-size'>
                    We are changing customer experience, helping customers, the enterprise and most importantly the support staff to solve customer issues using an integrated platform that has visibility across the enterprise.
                    </h6>
                </div>

                <div className='img-Tab p-2 '>
                    <img className='img_EI_APP' src={support_img} alt='Enterprise AI Apps' />
                </div>


                <div className='main-enter-plathform mt-5'>
                    <h4 style={{fontSize:'1.5rem',fontWeight:'bold'}}>AI Accounting for Enterprises
                    </h4>
                </div>
                <div className='enter-plathform-text mt-2'>
                    <h6 class='main-pages-products-sub-text  text-size'>
                    We have always believed accounting can be made simple, especially in the developing world. With AI, we are making it simpler, faster, and better. 


                    </h6>
                </div>

                <div className='img-Tab p-2 mb-5 '>
                    <img className='img_EI_APP' src={acc_img} alt='Enterprise AI Apps' />
                </div>




            </div>
        </div>
    )
}

export default Enterprise_AI_Platform