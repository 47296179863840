import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const showSuccesToast = (message,navigate) => {
    toast.success(message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        className: 'toast-tostify',
        onClose: () => {
            navigate('/');
        }
    });
};

