import React from 'react'
import './Main.css'
import logo from '../../Assets/BlackLogo.png'
import Navbar from './Navbar'


const Page1 = () => {


  return (
    <div className="container-fluid position-relative" style={{ minHeight: '100vh' }}>
    <div className="video-background">
        <video autoPlay muted loop id="background-video">
            <source src="https://storage.googleapis.com/operforce/Assets/Videos/8419548-hd_1080_1080_30fps.mp4" type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    </div>
    <div className="container-fluid" style={{ paddingBottom: '100px' }}> {/* Added paddingBottom here */}
        <div className="row ms-xl-5 me-xl-5">
            <Navbar menu={'Home'} />
        </div>
        <div className="row p-3">
            <div className="col-sm-12 d-flex align-items-center justify-content-center mt-5">
                <img src={logo} alt="" className="img-fluid  mt-5" style={{ maxHeight: '100px', minWidth: '200px' }} />
            </div>
            <div className="col-sm-12 d-flex align-items-start justify-content-center mt-lg-3 mt-3">
   
                <h2 class='fonts-for-use mt-lg-3 mt-3 home-page-slogan ms-lg-5 homemade-apple-regular' style={{fontSize: '1.6rem',fontWeight:'600',color:'white'}}>Building new possibilities</h2>
            </div>
            <div className="col-sm-12 d-flex align-items-start justify-content-center" style={{marginTop:'7rem '}}>
                <h2 class='fonts-for-use mt-lg-5 mt-5 home-page-heading lato-light' style={{fontSize:'1.3rem' ,paddingLeft:'2rem'}}>Enterprises - Want to AI-fy your Business ?</h2>
            </div>
            <div className="col-sm-12 d-flex align-items-start justify-content-center  mt-4 ps-lg-5">
                <a href='/contact-sales' className="btn home-page-btn btn-lg mb-5 fonts-for-use">Contact Sales</a>
            </div>
        </div>
    </div>
    <div className="row d-flex align-items-center justify-content-center gap-3 position-absolute" style={{ bottom: '0', width: '100%' }}>
        <h6 className="text-center footer-about-us-color mt-5 source-sans-3 copyright-font">Operforce AI</h6>
        <h6 className="text-center mb-4 footer-about-us-color source-sans-3 copyright-font">Copyright @Operforce All Rights Reserved.</h6>
    </div>
</div>


  
  )
}

export default Page1