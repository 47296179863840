import React from 'react'
import {useState,useEffect,useRef} from 'react'
import axios from 'axios'
import logo from '../../Assets/Logo5.png'
import { useHistory,Link,useNavigate } from 'react-router-dom';
import profile from '../../Assets/No_Profile_Pic.jpeg'
import { API_URL } from '../../Global_Constants'

const Org2 = () => {
    const [menu,setMenu]=useState('')
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const sessionDataString = sessionStorage.getItem('sessionData');
    const sessionData = JSON.parse(sessionDataString);
    const [userName,setUserName]=useState('')
    const [userProfile,setUserProfile]=useState([])
    const [userProfilePhoto,setUserProfilePhoto]=useState(profile)
    const [changePhoto,setChangePhoto]=useState(false)
    const [image,setImage]=useState(null)
    const dropdownRef = useRef(null);
    const navigate=useNavigate()

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
   
    const toggleDropdown = () => {
        setIsDropdownOpen(prevState => !prevState);
    };

    const handleMenuChange = (e) => {
        const selectedValue = e.target.value;
        if (selectedValue !== '') {
          window.location.href = '/apps';
        }
        setMenu(selectedValue);
      };

      const handleLogout = () => {
        sessionStorage.removeItem('sessionData');
        localStorage.removeItem('sessionData');
        localStorage.removeItem('rzp_device_id')
        localStorage.removeItem('rzp_checkout_anon_id')
        navigate('/login');
      };

      const getUserProfileById=async()=>{
          var formData=new FormData()
          formData.append('org_id',sessionData.session_record.id)
          formData.append('user_id',sessionData.session_record.login_id)
          try{
              const res=await axios.post(API_URL+'/user_show_profile_by_id/',formData)
               if(res.data.data!==null)
               {
                if(res.data.data.length>0)
                {
                setUserProfile(res.data.data[0])
                }
               }
          }
          catch(error)
          {
            console.log(error)
          }
      }

      const fetchUserProfilePhoto=()=>{
        console.log(userProfile)
        console.log(userProfile.profile_pic)
        if(userProfile.profile_pic!==null && userProfile.profile_pic!==undefined)
        {
            console.log('yes')
            const profile_url=userProfile.profile_pic;
            console.log(profile_url)
            let str = profile_url.toString();
            let GS_URL = "https://storage.cloud.google.com/";
            let  img_src = GS_URL + str.slice(5);
            console.log(img_src)
            setUserProfilePhoto(img_src)
        }
      }
      const handleImageChange = (e) => {
        setImage(e.target.files[0]);
      };

      const handleChangeProfilePhoto=async()=>{
          const formData=new FormData()
          formData.append('org_id',sessionData.session_record.id)
          formData.append('user_id',sessionData.session_record.login_id)
          formData.append('image',image)
          const response = await axios.post(API_URL+'/user_upload_profile/',formData)
          if(response.data.data!==null)
          {
            getUserProfileById()
          }
      }

      const fetchEmployeeNameById = async () => {
        var formData = new FormData();
        formData.append('org_id', sessionData.session_record.id);
        formData.append('emp_id', sessionData.session_record.login_id);
        try {
            const res = await axios.post(API_URL+'/hr_app_get_employee_name/', formData);
            console.log(res);
            if(res.data.data!==null){
            const responseData=res.data.data;
            if(responseData){
            setUserName(responseData.fullname)
            }
            } 
        } catch (error) {
            console.error(error); 
        }
        };

      useEffect(()=>{
         fetchEmployeeNameById()
         getUserProfileById()
      },[])

      useEffect(()=>{
        fetchUserProfilePhoto()
      },[userProfile])
      
  return (
    <div class="container-fluid org2-main">
        <div className="row">
            <div className="col-sm-12">
                <div className="container">
                    <div className="row">
                    <nav className='navbar navbar-expand-lg fixed top' style={{ height: '100px' }}>
                            <div className="container-fluid">
                                <a className='navbar-brand me-auto'><img src={logo} style={{ height: '80px', width: '80px' }} alt="logo" />OperforceAI</a>
                                <button className='navbar-toggler' type='button' data-bs-toggle='offcanvas'
                                    data-bs-target='#offcanvasNavbar' aria-controls='offcanvasNavbar'
                                    aria-label='Toggle navigation'>
                                    <span className='navbar-toggler-icon'></span>
                                </button>
                                <div className="offcanvas offcanvas-end" tabIndex='-1' id='offcanvasNavbar'
                                    aria-labelledby='offcanvasNavbarLabel'>
                                    <div className="offcanvas-header">
                                        <h5 className='offcanvas-title' id='offcanvasNavbarLabel'>OperforceAI</h5>
                                        <button type='button' className="btn-close" data-bs-dismiss='offcanvas'
                                            aria-label='Close'>
                                        </button>
                                    </div>
                                    <div className="offcanvas-body">
                                        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                            <li className={`nav-item dropdown ${isDropdownOpen ? 'show' : ''}`}>
                                                <a className="nav-link dropdown-toggle" href="#" onClick={toggleDropdown}>
                                                <img src={userProfilePhoto} style={{ height: '30px', width: '30px',borderRadius:'3px',margin:'10px' }} alt="profile" />
                                                {userName && userName.charAt(0).toUpperCase() + userName.slice(1)}
                                                </a>
                                                <div ref={dropdownRef} className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}  >
                                                    <a className="dropdown-item dropdown-profile-pic" data-bs-toggle="modal" data-bs-target="#changeProfilePhotoNavbar"> <img src={userProfilePhoto} style={{ height: '120px', width: '120px',borderRadius:'3px',margin:'10px' }} alt="profile" /></a>
                                                    <a className="dropdown-item" onClick={handleLogout}>Logout</a>
                                                    <a className="dropdown-item" href='/update_password'>Reset Password</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                    </nav>
                    </div>
                    <div className="row">
                    <div className="modal fade" id="changeProfilePhotoNavbar" tabIndex="-1" aria-labelledby="changeProfilePhotoNavbarLabel" aria-hidden="true">
                         <div className="modal-dialog modal-lg">
                          <div className="modal-content">
                              <div className="modal-header">
                                  <h5 className="modal-title" id="changeProfilePhotoNavbarLabel">Profile Photo</h5>
                                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div className="modal-body">
                                  <div className='row d-flex justify-content-center align-items-center'>
                                        {!image && (
                                          <div>
                                            <img src={userProfilePhoto} alt="Banner" style={{width:'250px',height:'250px',marginTop:'30px',marginLeft:'20px',borderRadius:'20px'}}/>
                                          </div>
                                        )}
                                        {image && (
                                            <div>
                                              <img src={URL.createObjectURL(image)} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px',marginLeft:'50px' }} />
                                            </div>
                                          )}
                                   </div>
                              </div>
                              <div className="modal-footer">
                                  <label htmlFor="file-upload" class="custom-resume-upload">
                                        Upload Photo
                                    </label>
                                  <input id="file-upload" type="file" accept="image/*" onChange={handleImageChange} className="ms-5" style={{display:'none'}} />
                                  <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={()=>{handleChangeProfilePhoto();setChangePhoto(!changePhoto)}}>Change Photo!</button>
                            </div>
                  </div>
              </div>
              </div>
                    </div>
                    <div className="row d-flex justify-content-center align-items-center flex-column">
                        <div className="col-sm-8 d-flex justify-content-center align-items-center">
                           <h4 class="org2-heading">At OperForce AI, Our mission is to digitalize and automate enterprise workflows that will enable them to operate and scale the business, and increase the revenue and profitability of the business</h4>
                        </div>
                        <div className="col-sm-8 d-flex flex-column justify-content-center align-items-center mt-5">
                            <div className="select" style={{ position: 'relative', display: 'inline-block' }}>
                            <select onChange={handleMenuChange}id="section_id"  style={{paddingTop:'15px',paddingBottom:'15px',paddingLeft:'25px',paddingRight:'25px',borderRadius:'10px',backgroundColor:'rgb(41,36,92)',color:'white'}}>
                            <option value="">Please select</option>
                            <option value="Manufacturing">Manufacturing</option>
                            <option value="IT">IT</option>
                             <option value="Hospitality">Hospitality</option>
                             <option value="HealthCare">HealthCare</option>
                        </select> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Org2