import React, { useState } from 'react';
import axios from 'axios';
import './Chatbot.css'; 
import Navbar from './Navbar';
import { API_URL } from '../../Global_Constants';
import yourImage from '../images/Support.png';
import './Fonts.css'

function Chatbot() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isImageVisible, setIsImageVisible] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userMessage = input;
    setMessages([...messages, { sender: 'user', text: userMessage }]);
    setInput('');
    setIsImageVisible(false); 

    try {
      const response = await axios.post(API_URL + '/api/chatbot/', { message: userMessage });
      const botMessage = response.data.reply;
      setMessages([...messages, { sender: 'user', text: userMessage }, { sender: 'bot', text: botMessage }]);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className='container-fluid support-chatbot'>
      <div className="container-fluid">
        <div className="row operforce-support ms-xl-5 me-xl-5">
          <Navbar menu={'Support'} />
        </div>
        <div className="container">
          <div className='d-flex align-item-center justify-content-center'>
          <h2 className='audiowide-regular lato-light'>Chat with the Best in Business!</h2>
          </div>
          
          {/* Conditionally render the image based on isImageVisible state */}
          {isImageVisible && (
            <div className='img-Tab p-2'>
              <img src={yourImage} alt='Enterprise AI platform' class='support-image product-images' style={{marginTop:'20px',borderRadius:'10px'}}/>
            </div>
          )}

          {messages.length > 0 && (
            <div className="mt-4 chat-window">
              {messages.map((msg, index) => (
                <div key={index} className={`message ${msg.sender}`}>
                  <p>{msg.text}</p>
                </div>
              ))}
            </div>
          )}
          
          <form onSubmit={handleSubmit} className="message-form">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Car drifts by a highway ad on Mars..."
              className="message-input"
            />
            <button type="submit" className="send-button">Send</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Chatbot;
