import React from 'react'
import './Main.css'
import Navbar from './Navbar'
import { MdDoubleArrow } from "react-icons/md";
import yourImage from '../images/image_v1_1727335712_2200.jpeg.png';
import { Link } from 'react-router-dom';

const Business_Consulting = () => {
    return (
        <div className='business_consulting'>
            <div className="row ms-xl-5 me-xl-5">
                <Navbar menu={'Products'} />
            </div>
            <div className='container-md ps-5 pe-5'>
                <div className='main-enter-plathform mt-5'>
                    <h4 style={{fontSize:'2rem' }}>Introducing Enterprise Business Consulting
</h4>
                </div>
                <div className='enter-plathform-text mt-4'>
                    <h6 class='main-pages-products-sub-text text-size'>
                    AI Business Consulting to AI-fi and digitize the business. Our team has experience helping customers around the world to solve business problems with AI and Machine Learning. In the Era of Enterprise Intelligence, We will understand your unique business challenges and work with you, and your team to take your Business to the Next Level.

                    </h6>
                </div>
                <div className='button_div_AI mb-2 mt-4'>
                    <Link to='/contact-sales' > <button className='btn contact_btn_EI'> Contact Sales </button></Link>
                    <Link to='/contact-marketing' > <button className='btn'>  Learn More <MdDoubleArrow />  </button></Link>
                </div>
                <div className='img-Tab p-2 mt-5'>
                    <img className='img_EI_APP' src={yourImage} alt='Enterprise AI Apps' />
                </div>

            </div>
        </div>
    )
}

export default Business_Consulting