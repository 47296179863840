import React,{useState} from 'react'
import Logo5 from '../images/Logo5.png'
const Navbar = ({menu}) => {
   

  return (
    <nav class='navbar navbar-expand-lg fixed top' style={{height:'100px'}}>
    <div className="container-fluid">
      <button class='navbar-toggler' type='button' data-bs-toggle='offcanvas'
      data-bs-target='#offcanvasNavbar' aria-controlls='offcanvasNavbar'
      aria-label='Toggle navigation'>
           <span class='navbar-toggler-icon'></span>
      </button>
      <div className="offcanvas offcanvas-end" tabindex='-1' id='offcanvasNavbar'
      aria-labelledby='offcanvasNavbarLabel'>
          <div className="offcanvas-header">
              <h5 class='offcanvas-title' id='offcanvasNavbarLabel'></h5>
              <button type='button' className="btn-close" data-bs-dismiss='offcanvas'
              aria-label='Close'> 
              </button>
          </div>
          <div className="offcanvas-body">
               <ul className="navbar-nav justify-content-start flex-grow-1 align-items-center text-align-center">
                  <li className="nav-item ms-lg-5 me-lg-5">
                      <a  className="nav-link active  main-nav-logo-font fonts-for-use" area-current='page'><img src={Logo5} style={{height:'100px',width:'100px'}}></img>OperforceAI</a>
                  </li>
                  <li className="nav-item m-4 ms-lg-5">
                      <a  className="nav-link active  main-nav-font raleway" area-current='page' href='/' style={{ fontWeight: menu === 'Home' ? 'bold' : 'normal' }} >Home</a>
                  </li>
                  <li className="nav-item m-4">
                      <a  className="nav-link active  main-nav-font raleway" area-current='page' href='/products'  style={{ fontWeight: menu === 'Products' ? 'bold' : 'normal' }} >Products</a>
                  </li>
                  <li className="nav-item m-4">
                      <a  className="nav-link active  main-nav-font raleway" area-current='page' href='/careers'  style={{ fontWeight: menu === 'Careers' ? 'bold' : 'normal' }} >Careers</a>
                  </li>
                  <li className="nav-item m-4">
                      <a  className="nav-link active  main-nav-font raleway" area-current='page' href='/support'  style={{ fontWeight: menu === 'Support' ? 'bold' : 'normal' }} >Support</a>
                  </li>
                  <li className="nav-item m-4">
                      <a  className="nav-link active main-nav-font raleway" area-current='page' href='/about_us'  style={{ fontWeight: menu === 'About' ? 'bold' : 'normal' }} >About Us</a>
                  </li>
               </ul>
          </div>
      </div>
     
   </div>
 </nav>
  )
}

export default Navbar