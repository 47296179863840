import React from 'react'; 
import {useEffect,useState,createContext} from 'react'
import { useLocation } from 'react-router-dom';
import axios from 'axios'; 
import Login from './Components/Main_Pages_Public/Login.jsx'
import Register from './Components/Main_Pages_Public/Register.jsx'
import Org from './Components/Main_Pages/Org_dummy.jsx'
import Apps from './Components/Main_Pages/Apps.jsx'
import { BrowserRouter as Router, Routes,Route,Navigate } from 'react-router-dom';
import BetaForm from './Components/Main_Pages_Public/Sign_up_for_beta.jsx';
import Careers from './Components/Main_Pages_Public/Careers.jsx';
import Careers_form from './Components/Main_Pages_Public/Careers_form.jsx';
import About_us from './Components/Main_Pages_Public/About_us.jsx';
import Update_Password from './Components/Main_Pages_Public/Update_Password.jsx'
import Page1 from './Components/Main_Pages_Public/Page1.jsx'

// import Page2 from './Components/Main_Pages_Public/Page2.jsx'
import Product_Page from './Components/Main_Pages_Public/Product_Page.jsx';
import Org2 from './Components/Main_Pages/Org.jsx'
import Enterprise_AI_Platform from './Components/Main_Pages_Public/Enterprise_AI_Platform.jsx';
import Enterprise_AI_Apps from './Components/Main_Pages_Public/Enterprise_AI_Apps.jsx';
import Business_Consulting from './Components/Main_Pages_Public/Business_Consulting.jsx';
import Chatbot from './Components/Main_Pages_Public/Chatbot.jsx';
import Contact_Sales from './Components/Main_Pages_Public/Contact_Sales.jsx';

/*import TimeOffPayOut from './Apps/HR/Components/EditTimeOff.jsx'*/
/*import TimeOffPayOut from './Apps/HR/Components/EditTimeOffPayOut.jsx'*/
function App() { 
  const location = useLocation();
  const [jobTitle,setJobTitle]=useState('')
  

  const checkSession = () => {
    const sessionDataString = localStorage.getItem('sessionData');
    return sessionDataString;
  };

  const PrivateRoute = ({ element: Element, ...rest }) => {
    return checkSession() ? <Element {...rest} /> : <Navigate to="/" />;
  }

  // useEffect(() => {
  //   const disableInspectElement = () => {
  //     document.onkeydown = function(e) {
  //       if (
  //         e.keyCode === 123 ||
  //         (e.ctrlKey && e.shiftKey && e.keyCode === 73) || // ctrl+shift+i
  //         (e.ctrlKey && e.shiftKey && e.keyCode === 74) || // ctrl+shift+j
  //         (e.ctrlKey && e.keyCode === 85) || // ctrl+u
  //         (e.ctrlKey && e.shiftKey && e.keyCode === 67) // ctrl+shift+c
  //       ) {
  //         return false;
  //       }
  //     };
  //     document.addEventListener('contextmenu', (e) => {
  //       e.preventDefault();
  //     });
  //   };

  //   disableInspectElement();
  // }, []);

    return( 
      <div> 
            <Routes>
            <Route path="/login" element={<Login />} />
              <Route path="/contact-marketing" element={<BetaForm />} />
            <Route path="/careers" element={<Careers setJobTitle={setJobTitle} />} />
            <Route path="/careers_form" element={<Careers_form jobTitle={jobTitle}/>} />
            <Route path="/about_us" element={<About_us />} />
            <Route path="/" element={<Page1 />}/>
            <Route path="/products/" element={<Product_Page />}/>

          

            <Route path="/register" element={<PrivateRoute element={Register} />} />
            <Route path="/update_password" element={<PrivateRoute element={Update_Password} />} />

            <Route path="/org_home" element={<PrivateRoute element={Org2} />} />
               <Route exact path='/support' element={<Chatbot />} />
	     <Route path="/products/enterprise_platform" element={<Enterprise_AI_Platform />} />
        <Route path="/products/enterprise_apps" element={<Enterprise_AI_Apps />} />
        <Route path="/products/business" element={<Business_Consulting />} />
	     <Route path="/contact-sales" element={<Contact_Sales />} />
           
            </Routes>
      </div> 
      )
  } 
  
export default App;
