import React from 'react'
import Logo5 from '../images/Logo5.png'
import { Link } from 'react-router-dom'
import Navbar from './Navbar'

const Careers = ({setJobTitle}) => {
  return (
    <div class='container-fluid careers-main'>
      <div className="container-fluid">
      <div className="row operforce-careers ms-xl-5 me-xl-5">
           <Navbar menu={'Careers'}/>
         </div>
        <div className="row ps-4 pe-4 pe-md-5 ps-md-5 d-flex flex-column align-items-center justify-content-center">
             <div className="col-12 col-lg-7 mt-3 d-lg-flex">
                 <div className="col-12 col-lg-7">
                 <h1 class='career-heading' style={{lineHeight:'1.5',fontSize:'3rem'}}>Marketing Manager</h1>
                 </div>
                 <div className="col-12 col-lg-5 d-flex  justify-content-lg-center justify-content-start">
                   <h4 class='career-heading' style={{lineHeight:'3'}}>Kharadi, Pune, India</h4>
                 </div>
             </div>
             <div className="col-12 col-lg-7">
             <hr style={{height:'2px'}}></hr>
             </div>
             <div className="col-12 col-lg-7  mt-4">
               <h6 class='careers-h6'>As a Marketing Manager, you will play a pivotal role in developing and executing strategic marketing initiatives to promote our Enterprise
                AI Platform and Enterprise AI Apps. You will be responsible for crafting compelling marketing campaigns, managing digital and traditional marketing channels, and building
                strong relationships with enterprises and partners. </h6>
             </div>
             <div className="col-12 col-lg-7  mt-3">
                <h5>Responsibilities</h5>
             </div>
             <div className="col-12 col-lg-7 ">
                <ul>
                    <li class='careers-li'>Develop and implement comprehensive marketing strategies to drive awareness and adoption of our enterprise AI platform and Enterprise AI Apps.</li>
                    <li class='careers-li'>Collaborate with cross-functional teams to create compelling marketing collateral, including website content, blog posts, case studies, and whitepapers.</li>
                    <li class='careers-li'>Plan and execute targeted marketing campaigns across digital and traditional channels, including email, social media, SEM, SEO, and events.</li>
                    <li class='careers-li'>Manage the company's online presence, including website optimization, content creation, and social media management.</li>
                    <li class='careers-li'>Analyze marketing metrics and KPIs to measure the effectiveness of campaigns and optimize marketing efforts for maximum impact.</li>
                    <li class='careers-li'>Conduct market research and competitive analysis.</li>
                    <li class='careers-li'>Build and maintain relationships with clients, partners, industry influencers, and media outlets to enhance brand visibility and credibility.</li>
                    <li class='careers-li'>Coordinate with sales teams to develop marketing materials and tools to support lead generation and conversion efforts.</li>
                    <li class='careers-li'>Stay updated on industry trends and best practices in marketing and technology, and incorporate relevant insights into our marketing strategies.</li>
                </ul>
             </div>
             <div className="col-12 mt-3 col-lg-7 ">
                <h5>Requirements</h5>
             </div>
             <div className="col-12 col-lg-7 ">
                <ul>
                    <li class='careers-li'>1-2 years of experience in marketing, with a focus on technology and Enterprise AI</li>
                    <li class='careers-li'>Bachelor's degree in Marketing, Business Administration, or a related field.</li>
                    <li class='careers-li'>Proven track record of developing and executing successful marketing campaigns across digital and traditional channels.</li>
                    <li class='careers-li'>Strong understanding of digital marketing tactics and tools, including email marketing, social media management, SEM, SEO, and analytics platforms.</li>
                    <li class='careers-li'>Excellent communication and interpersonal skills, with the ability to build rapport and credibility with internal and external stakeholders.</li>
                    <li class='careers-li'>Strong analytical skills, with the ability to interpret data and metrics to drive informed marketing decisions.</li>
                    <li class='careers-li'>Creative thinker with a passion for innovation and problem-solving.</li>
                    <li class='careers-li'>Ability to multitask and prioritize in a fast-paced, deadline-driven environment.</li>
                </ul>
             </div>
             <div className="col-12 mt-3 mb-5 col-lg-7 ">
             <Link to='/careers_form'><button  className="btn  careers-apply-button" onClick={()=>setJobTitle('Marketing Manager')}>Apply Now</button></Link> 
             </div>
         </div>
         <div className="row ps-4 pe-4 pe-md-5 ps-md-5 d-flex flex-column align-items-center justify-content-center">
             <div className="col-12 col-lg-7 mt-3 d-lg-flex">
                 <div className="col-12 col-lg-7">
                 <h1 class='career-heading' style={{lineHeight:'1.5',fontSize:'3rem'}}> Mobile App Developer </h1>
                 </div>
                 <div className="col-12 col-lg-5 d-flex  justify-content-lg-center justify-content-start">
                   <h4 class='career-heading' style={{lineHeight:'3'}}>Kharadi, Pune, India</h4>
                 </div>
             </div>
             <div className="col-12 col-lg-7">
             <hr style={{height:'2px'}}></hr>
             </div>
             <div className="col-12 col-lg-7  mt-4">
               <h6 class='careers-h6'>As a Mobile App Developer specializing in Flutter, you will design and implement innovative mobile applications that 
               enhance user experiences. You will collaborate with cross-functional teams, develop high-performance apps, and ensure seamless integration 
               with backend services, contributing to the success of our mobile initiatives. </h6>
             </div>
             <div className="col-12 col-lg-7  mt-3">
                <h5>Responsibilities</h5>
             </div>
             <div className="col-12 col-lg-7 ">
                <ul>
                    <li class='careers-li'>Design, develop and test, automate and maintain mobile applications using Flutter or React native</li>
                    <li class='careers-li'>Collaborate with cross-functional teams to design and ship new features.</li>
                    <li class='careers-li'>Write great and highly responsive code.</li>
                    <li class='careers-li'>Do code reviews and provide constructive feedback to the team members.</li>
                    <li class='careers-li'>Troubleshoot and debug applications to ensure optimal performance.</li>
                    <li class='careers-li'>Integrate with RESTful APIs and third-party libraries.</li>
                    <li class='careers-li'>Participate in Agile development processes.</li>
                </ul>
             </div>
             <div className="col-12 mt-3 col-lg-7 ">
                <h5>Requirements</h5>
             </div>
             <div className="col-12 col-lg-7 ">
                <ul>
                    <li class='careers-li'>2-5 years of experience in Flutter and React Native</li>
                    <li class='careers-li'>Strong understanding of Dart programming language.</li>
                    <li class='careers-li'>Familiarity with RESTful APIs and integration with back-end services.</li>
                    <li class='careers-li'>Experience with version control systems and CI/CD infrastructure</li>
                    <li class='careers-li'>Knowledge of mobile app design principles and best practices.</li>
                    <li class='careers-li'>Ability to work in a team-oriented, collaborative environment.</li>
                    <li class='careers-li'>Strong problem-solving skills and attention to detail.</li>
                    <li class='careers-li'>Excellent communication skills.</li>
                </ul>
             </div>
             <div className="col-12 mt-3 col-lg-7 ">
                <h5>Preferred Qualifications:</h5>
             </div>
             <div className="col-12 col-lg-7 ">
                <ul>
                    <li class='careers-li'>Experience with native mobile development (iOS/Android) is a plus.</li>
                    <li class='careers-li'>Familiarity with cloud platforms (e.g., Azure, AWS, etc) and CI/CD practices.</li>
                    <li class='careers-li'>Previous experience in publishing apps on the App Store and Google Play.</li>
                    <li class='careers-li'>Have a portfolio that displays your best work.</li>
                </ul>
             </div>
             <div className="col-12 mt-3 mb-5 col-lg-7 ">
             <Link to='/careers_form'><button  className="btn  careers-apply-button" onClick={()=>setJobTitle('Mobile App Flutter')}>Apply Now</button></Link> 
             </div>
         </div>
         <div className="row ps-4 pe-4 pe-md-5 ps-md-5 d-flex flex-column align-items-center justify-content-center">
           <div className="col-12 col-lg-7 mt-3 d-lg-flex">
                 <div className="col-12 col-lg-7">
                 <h1 class='career-heading' style={{lineHeight:'1.5',fontSize:'3rem'}}>Sr. Software Engineer - Frontend</h1>
                 </div>
                 <div className="col-12 col-lg-5 d-flex  justify-content-lg-center justify-content-start">
                   <h4 class='career-heading' style={{lineHeight:'3'}}>Kharadi, Pune, India</h4>
                 </div>
             </div>
             <div className="col-12 col-lg-7">
             <hr style={{height:'2px'}}></hr>
             </div>
             <div className="col-12 mt-4 col-lg-7 ">
               <h6 class='careers-h6'>We are seeking a Software Engineer - Frontend to join our dynamic team. In this role, you will be responsible 
                 for developing and maintaining user-friendly web applications for our Enterprise AI Platform™ and Enterprise AI Apps™. 
                 You’ll work closely with designers, backend engineers, and managers to create seamless and engaging user experiences.</h6>
             </div>
             <div className="col-12 mt-3 col-lg-7 ">
                <h5>Responsibilities</h5>
             </div>
             <div className="col-12 col-lg-7 ">
                <ul>
                    <li class='careers-li'>Design and implement responsive, high-performance user interfaces for web applications.</li>
                    <li class='careers-li'>Collaborate with UX/UI designers to translate designs into functional and visually appealing features.</li>
                    <li class='careers-li'>Integrate APIs and backend services to deliver dynamic and interactive experiences.</li>
                    <li class='careers-li'>Optimize applications for maximum speed and scalability.</li>
                    <li class='careers-li'>Write clean, maintainable code and conduct code reviews to uphold quality standards.</li>
                    <li class='careers-li'>Stay updated on the latest technologies and best practices to drive continuous improvement.</li>
                </ul>
             </div>
             <div className="col-12 mt-3 col-lg-7 ">
                <h5>Requirements</h5>
             </div>
             <div className="col-12 col-lg-7 ">
                <ul>
                    <li class='careers-li'>Bachelor’s degree in Computer Science, Web Development, or a related field.</li>
                    <li class='careers-li'>3+ years of experience in frontend development, preferably in a startup environment.</li>
                    <li class='careers-li'>Proficiency in JavaScript, React Native Advanced, HTML, and CSS, with experience in modern frameworks such as Flutter, etc.</li>
                    <li class='careers-li'>Familiarity with state management libraries (e.g., Redux, Vuex) and responsive design principles.</li>
                    <li class='careers-li'>Understanding of RESTful APIs and experience with asynchronous programming.</li>
                    <li class='careers-li'>Strong problem-solving skills and ability to work collaboratively in a team.</li>
                    <li class='careers-li'>Excellent communication skills and a passion for creating exceptional user experiences.</li>
                </ul>
             </div>
             <div className="col-12 mt-3 mb-5 col-lg-7 ">
                 <Link to='/careers_form'><button  className="btn careers-apply-button" onClick={()=>setJobTitle('Sr. Software Engineer - Frontend')}>Apply Now</button></Link> 
             </div>
         </div>
         <div className="row ps-4 pe-4 pe-md-5 ps-md-5 d-flex flex-column align-items-center justify-content-center">
           <div className="col-12 col-lg-7 mt-3 d-lg-flex">
                 <div className="col-12 col-lg-7">
                 <h1 class='career-heading' style={{lineHeight:'1.5',fontSize:'3rem'}}>Sr. Software Engineer - Backend</h1>
                 </div>
                 <div className="col-12 col-lg-5 d-flex  justify-content-lg-center justify-content-start">
                   <h4 class='career-heading' style={{lineHeight:'3'}}>Kharadi, Pune, India</h4>
                 </div>
             </div>
             <div className="col-12 col-lg-7">
             <hr style={{height:'2px'}}></hr>
             </div>
         <div className="col-12 col-lg-7">
             <hr style={{height:'2px'}}></hr>
             </div>
             <div className="col-12 mt-4 col-lg-7 ">
               <h6 class='careers-h6'>We are seeking a Senior Software Engineer - Backend to lead the design and implementation of scalable, high-performance 
                 backend systems for our Enterprise AI Platform™ and Enterprise AI Apps™.  You will play a crucial role in developing robust APIs and 
                 microservices that power our AI Platform. </h6>
             </div>
             <div className="col-12 mt-3 col-lg-7 ">
                <h5>Responsibilities</h5>
             </div>
             <div className="col-12 col-lg-7 ">
                <ul>
                    <li class='careers-li'>Design, develop, and maintain scalable backend platform, APIs, and services.</li>
                    <li class='careers-li'>Collaborate with managers, data scientists, and frontend engineers to design and write ML and AI algorithms.</li>
                    <li class='careers-li'>Optimize database performance and manage data storage solutions.</li>
                    <li class='careers-li'>Optimize applications for maximum speed and scalability.</li>
                    <li class='careers-li'>Implement best practices for code quality, testing, and documentation.</li>
                </ul>
             </div>
             <div className="col-12 mt-3 col-lg-7 ">
                <h5>Requirements</h5>
             </div>
             <div className="col-12 col-lg-7 ">
                <ul>
                    <li class='careers-li'>Bachelor’s/Master’s degree in Computer Science or a related field.</li>
                    <li class='careers-li'>3+ years of experience in backend software development, preferably in a startup environment.</li>
                    <li class='careers-li'>Proficiency in Python, Java, React native, Flutter and frameworks.</li>
                    <li class='careers-li'>Experience with cloud platforms (e.g., AWS, GCP, Azure) and microservices architecture.</li>
                    <li class='careers-li'>Strong understanding of RESTful APIs and web services.</li>
                    <li class='careers-li'>Familiarity with database technologies (e.g., SQL, NoSQL) and data modeling.</li>
                    <li class='careers-li'>Excellent problem-solving skills and ability to work in a dynamic, collaborative team environment.</li>
                </ul>
             </div>
             <div className="col-12 mt-3 mb-5 col-lg-7 ">
                 <Link to='/careers_form'><button  className="btn careers-apply-button" onClick={()=>setJobTitle('Sr. Software Engineer - Backend')}>Apply Now</button></Link> 
             </div>
         </div>
         <div className="row d-flex align-items-center justify-content-center mt-5 gap-3">
              <h6 class='text-center footer-about-us-color source-sans-3 copyright-font'>Operforce AI</h6>
              <h6 class='text-center mb-4 footer-about-us-color source-sans-3 copyright-font'>Copyright @ 2024 Operforce - All Rights Reserved.</h6>
         </div>
      </div>
    </div>
  )
}

export default Careers
