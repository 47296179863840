import React, { useState } from 'react';
import axios from 'axios';
import {API_URL} from '../../Global_Constants'
import { useEffect} from "react";
import Navbar from './Navbar';
import { ToastContainer } from 'react-toastify';
import { showSuccesToast } from './Main_Pages_Success_Images';
import { useNavigate } from 'react-router-dom';


const Contact_Sales = () => {
  const [error, setError] = useState('');
  const [success,setSuccess]=useState(false)
  const navigate = useNavigate()

  const handleChange = (event) => {
    if (!event.target.value) {
      setError('Input cannot be empty');
    } else {
      setError('');
    }
  };

  const setRefreshPage=()=>{
    setSuccess(false)
  }

  useEffect(()=>{
      setRefreshPage()
  },[])

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      const response = await axios.post(
        API_URL + '/contact_sales/',
        formData
      );
      if(response.data.data!==null)
      {
        showSuccesToast('Form Submitted Successfully',navigate)
      }

    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

 

  return (
         <div className="container-fluid beta-container">
          <ToastContainer/>
          <div className="row ms-xl-5 me-xl-5">
            <Navbar/>
          </div>
          <div className="row mt-5 d-flex align-items-center justify-content-center">
          <form onSubmit={handleSubmit}>
              <div className="container-fluid " style={{height:'100%'}}>
          <div className="row d-flex align-items-center justify-content-center" style={{height:'100%'}}>
              <div className="col-12 col-lg-6" style={{height:'600px'}}>
                <div className="container-fluid">
                 <div className="row d-flex flex-column justify-content-center align-items-center">
                    <div className="col-12 d-flex align-items-center justify-content-center">
                         <h1 class="mb-4 source-sans-3 sign-up-beta-heading">Contact Sales</h1>
                    </div>
                    <div className="col-12  d-flex align-items-center justify-content-center p-0" style={{width:'700px',minWidth:'250px',gap:'10px'}}>
                        <input class="form-control beta-one-column"  type="text" name="business_name"  placeholder="Business Name* " required />
                    </div> 
                    <div className="col-12 d-flex flex-column flex-lg-row align-items-center justify-content-center p-0 mt-4"style={{width:'700px',minWidth:'250px'}}>
                        <div className="col-12 col-lg-6 d-flex align-items-center justify-content-lg-end p-0  ">
                          <input class="form-control me-lg-2 beta-two-columns"  type="text" name="full_name"  placeholder="Full Name*" required />
                        </div>
                        <div className="col-12 col-lg-6 d-flex align-items-center justify-content-lg-start p-0 mt-4 mt-lg-0">
                          <input class="form-control beta-two-columns"  type="text" name="job_title" onChange={handleChange} placeholder="Job Title*" required />
                        </div>     
                   </div> 
                    <div className="col-12 d-flex flex-column flex-lg-row align-items-center justify-content-center p-0 mt-4"style={{width:'700px',minWidth:'250px'}}>
                        <div className="col-12 col-lg-6 d-flex align-items-center justify-content-lg-end p-0  ">
                          <input class="form-control me-lg-2 beta-two-columns"  type="email" name="email"  placeholder="Email*" required />
                        </div>
                        <div className="col-12 col-lg-6 d-flex align-items-center justify-content-lg-start p-0 mt-4 mt-lg-0">
                          <input class="form-control beta-two-columns"  type="text" name="mobile" onChange={handleChange} placeholder="Phone Number" required />
                        </div>     
                   </div>  
                   <div className="col-12 d-flex flex-column flex-lg-row align-items-center justify-content-center p-0 mt-4"style={{width:'700px',minWidth:'250px'}}>             
                    <div className="col-12 col-lg-6 d-flex align-items-center justify-content-lg-end p-0  ">
                    <select 
                        className="form-control me-lg-2 beta-two-columns" 
                        name="country_name" 
                        required
                      >
                        <option value="" disabled selected>Select Country*</option>
                        <option value="IN">India</option>
                        <option value="US">USA</option>
                        <option value="GB">United Kingdom</option>
                        <option value="FR">France</option>
                        <option value="DE">Germany</option>
                        <option value="IT">Italy</option>
                        <option value="CA">Canada</option>
                        <option value="JP">Japan</option>
                        {/* Add more options as needed */}
                      </select>
                    </div>
                    <div className="col-12 col-lg-6 d-flex align-items-center justify-content-lg-start p-0 mt-4 mt-lg-0" >
                         <input class="form-control beta-two-columns" type="text" name="address"  placeholder="Address" />
                    </div>   
                    </div>
                    <div className="col-12  d-flex align-items-center  p-0" style={{width:'700px',minWidth:'250px',gap:'10px'}}>
                        <label class=" mt-3">Please select the Industry ?</label>
                    </div> 
                    <div className="col-12  d-flex align-items-center justify-content-center p-0 mt-3" style={{width:'700px',minWidth:'250px',gap:'10px'}}>
                        <select 
                        className="form-control  beta-one-column" 
                        name="industry_type" 
                        required
                      >
                        <option value="" disabled selected>Select Industry*</option>
                        <option value="manufacturing">Manufacturing</option>
                        <option value="services">Services/Consulting</option>
                        <option value="information_technology">Information Technology</option>
                        <option value="real_estate">Real Estate</option>
                        <option value="healthcare">Healthcare</option>
                        <option value="hospitality">Hospitality</option>
                        <option value="bpo">BPO</option>
                        <option value="ngo">NGO</option>
                        <option value="trading_and_education">Trading and Education</option>
                        <option value="other">Other</option>
                      </select>
                    </div>        
                    <div className="col-12  d-flex align-items-center  p-0" style={{width:'700px',minWidth:'250px',gap:'10px'}}>
                        <label class=" mt-3">Which Product you are interested in?</label>
                    </div> 
                    <div className="col-12  d-flex align-items-center justify-content-center p-0 mt-3" style={{width:'700px',minWidth:'250px',gap:'10px'}}>
                        <select 
                        className="form-control  beta-one-column" 
                        name="product_type" 
                        required
                      >
                        <option value="" disabled selected>Select Product*</option>
                        <option value="enterprise_ai_platform">Enterprise AI platform</option>
                        <option value="enterprise_ai_apps">Enterprise AI Apps</option>
                        <option value="business_consulting">Business Consulting</option>
                      </select>
                    </div>                 
                    <div className="col-12  d-flex align-items-center justify-content-center p-0 mt-5" >
                      <button type="submit" class="sign-up-beta-register-button register-button fonts-for-use mb-5" >Submit</button>
                    </div>                   
                  </div>
                </div>
              </div>
          </div>
        </div>
        </form>
          </div>
         </div>
  );
};

export default Contact_Sales;
