import React from 'react'
import './Main.css'
import  {Link } from 'react-router-dom'
import Navbar from './Navbar'
import businessConsultingImage from '../images/AI_Consultancy.png'
import enterpriseAiPlatformImage from '../images/AI_Platform.png'
import enterpriseAiAppsImage from '../images/AI_App.png'

const Page1 = () => {
  return (
    <div class="container-fluid page2-main">
       <div className="video-background">
            <video autoPlay muted loop id="background-video">
               <source src="https://storage.googleapis.com/operforce/Assets/Videos/117924-713330888_small.mp4" type="video/mp4" />
               Your browser does not support the video tag.
            </video>
         </div>
         <div className="container-fluid">
         <div className="row ms-xl-5 me-xl-5">
           <Navbar menu={'Products'}/>
          </div>
         <div className="row page2-row d-flex flex-column  align-items-center justify-content-center p-3">
                <div className="col-sm-12 d-flex align-items-center justify-content-center mt-3 mb-3 audiowide-regular lato-light" style={{fontSize:'36px',color:'black'}}>
                            Products
                </div>
                <div className="col-12 col-md-9 col-xl-6 d-flex align-items-center justify-content-center page2-mission mb-3 mt-3">
                         <h6 class="page2-mission montserrat" style={{color:'black'}}>
                         In our very first release, we are releasing support for the Enterprise AI Platform™ built
                          with AI-first and security-first principles from Day One. It includes an intial set of Enterprise
                           AI Apps™ that will propell your business into the era of enterprise intelligence - full of possibilities.
                         </h6>
                </div>
                <div className="col-sm-12 mt-4">
                     <div className="container-fluid">
                     <div className="row  d-flex justify-content-center align-items-start ">
                              <div className="col-12 col-xl-3 col-lg-4 " >
                                 <Link to='/products/enterprise_platform' className="text-decoration-none d-flex flex-column align-items-center  product-box">
                                      <h5 class='product-page-apps-heading lato-light' style={{fontSize: '28px'}}>Enterprise AI Platform™</h5>
                                      <img src={enterpriseAiPlatformImage} alt='Enterprise AI platform' class='img-fluid-products product-images' style={{marginTop:'20px',borderRadius:'50%'}}/>
                                      <h6 class='montserrat product-bottom-font' style={{lineHeight:'2',color:'black',textAlign:'justify',marginTop:'20px',width:'70%',maxWidth:'400px'}}> A platform that helps your business to build new possibilities, capturing the true value of your Enterprise </h6>
                                  </Link>
                              </div>
                            <div className="col-12 col-xl-3 col-lg-4 " >
                                 <Link to='/products/enterprise_apps' className="text-decoration-none d-flex flex-column align-items-center  product-box">
                                    <h5 class='product-page-apps-heading lato-light' style={{fontSize: '28px'}}>Enterprise AI Apps™</h5> 
                                    <img src={enterpriseAiAppsImage} alt='Enterprise AI Apps'  class='img-fluid-products' style={{marginTop:'20px',borderRadius:'50%'}}/>
                                    <h6 class='montserrat product-bottom-font' style={{lineHeight:'2',textAlign:'justify',color:'black',marginTop:'20px',width:'70%',maxWidth:'400px'}}>
                                      Apps that helps you to automate Enterprise work flows in a better and finer way 
                                    </h6>
                                 </Link>
                            </div>
                            <div className="col-12 col-xl-3 col-lg-4 " >
                                <Link to='/products/business' className="text-decoration-none d-flex flex-column align-items-center  product-box">
                                     <h5  class='product-page-apps-heading lato-light' style={{fontSize: '28px'}}>Business Consulting</h5>
                                     <img src={businessConsultingImage} alt='Business Consulting' class='img-fluid-products' style={{borderRadius:'50%',marginTop:'20px'}}/>
                                     <h6 class='montserrat product-bottom-font' style={{lineHeight:'2',color:'black',textAlign:'justify',marginTop:'20px',width:'70%',maxWidth:'400px'}}>
                                     Business Consulting and strategy for Digitalization and AI-fication of Enterprise.
                                    </h6>
                                </Link>
                            </div>
                     </div>
                     </div>
                </div>
         </div>
         <div className="row d-flex align-items-center justify-content-center mt-5 gap-3">
              <h6 class='text-center footer-about-us-color source-sans-3 copyright-font'>Operforce AI</h6>
              <h6 class='text-center mb-4 footer-about-us-color source-sans-3 copyright-font'>Copyright @ 2024 Operforce. All Rights Reserved.</h6>
         </div>
         </div>
    </div>
  )
}

export default Page1