import React from 'react'
import './Main.css'
import Navbar from './Navbar'
import { MdDoubleArrow } from "react-icons/md";
import yourImage from '../images/image_v1_1727335190_2215.jpeg.png';
import { Link } from 'react-router-dom';

const Enterprise_AI_Apps = () => {
    return (
        <div className='enterprise_apps'>
            <div className="row ms-xl-5 me-xl-5">
                <Navbar menu={'Products'} />
            </div>
            <div className='container-md ps-5 pe-5'>
                <div className='main-enter-plathform mt-5'>
                    <h4 style={{fontSize:'2rem' }}>Introducing Enterprise AI Apps™</h4>
                </div>
                <div className='enter-plathform-text mt-4'>
                    <h6 class='main-pages-products-sub-text text-size'>
                    AI Apps that help to build new possibilities for your enterprise business. They AI power some workflows while disrupt others to truly take your enterprise into the Era of Enterprise Intelligence.  

                    </h6>
                </div>
                <div className='button_div_AI mb-2 mt-4'>
                    <Link to='/contact-sales' > <button className='btn contact_btn_EI'> Contact Sales </button></Link>
                    <Link to='/contact-marketing' > <button className='btn'>  Learn More <MdDoubleArrow />  </button></Link>
                </div>
                <div className='img-Tab p-2 mt-5'>
                    <img className='img_EI_APP' src={yourImage} alt='Enterprise AI Apps' />
                </div>

                <div className='main-enter-plathform mt-5'>
                    <h4>Magical Marketing</h4>
                </div>
                <div className='enter-plathform-text mt-2'>
                    <h6 class='main-pages-products-sub-text text-size'>
                    We are making marketing Magical for better. It's going to provide SMBs and MSMEs the same power of advertising as the top brands, leveling the playing field.

                    </h6>
                </div>

                <div className='main-enter-plathform mt-3'>
                    <h4>Integrated Sales 
</h4>
                </div>
                <div className='enter-plathform-text mt-2 mb-5'>
                    <h6 class='main-pages-products-sub-text text-size'>
                    With AI powered Integrated Sales, the enterprise sales staff will be equipped with all the help they need to help customers.

                    </h6>
                </div>

            </div>
        </div>
    )
}

export default Enterprise_AI_Apps